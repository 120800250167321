import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index1111111"} />
		<Helmet>
			<title>
				Разработка крутых приложений | Кодовое слово  
			</title>
			<meta name={"description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:title"} content={"Наши проекты | Кодовое слово  "} />
			<meta property={"og:description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/favicon.ico?v=2021-08-10T08:08:05.993Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
		</Helmet>
		<Box
			background="#BAA9FF"
			quarkly-title="page"
			width="100%"
			height="max-content"
			padding="40px 0 149px 0"
			display="flex"
			align-items="center"
			justify-content="flex-start"
			flex-direction="column"
		>
			<Box
				quarkly-title="contentMain"
				width="100%"
				height="max-content"
				max-width="1200px"
				flex-wrap="wrap"
				display="flex"
			>
				<Box
					width="100%"
					max-width="600px"
					background="#ffffff"
					border-width="2px"
					border-style="solid"
					border-color="#000000"
					border-radius="8px"
					quarkly-title="companyMain"
					display="flex"
					flex-direction="column"
					justify-content="center"
					align-items="center"
					padding="40px 40px 40px 40px"
					min-height="383px"
					sm-margin="0 10px 0 10px"
					sm-padding="24px 24px 24px 24px"
					height="max-content"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						align-items="center"
						sm-flex-direction="row"
					>
						<Image
							src="https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=200"
							display="block"
							object-fit="cover"
							width="120px"
							height="120px"
							border-width="2px"
							border-style="solid"
							border-color="#EEEEEE"
							border-radius="50%"
							margin="0 0 24px 0"
							min-width="120px"
							min-height="120px"
							sm-margin="0 24px 24px 0"
						/>
						<Text
							margin="0px 0px 45px 0px"
							text-align="center"
							font="32px/28.8px --fontFamily-googleMontserrat"
							quarkly-title="H1"
							sm-text-align="left"
						>
							Big brand name
						</Text>
					</Box>
					<Text
						margin="0px 0px 30px 0px"
						font="400 16px --fontFamily-googleMontserrat"
						color="rgba(0, 0, 0, 0.8)"
						quarkly-title="aboutText"
						display="none"
						sm-display="block"
					>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies amet scelerisque eu pellentesque. Est placerat donec magna consequat pretium venenatis amet dolor. Enim praesent molestie quam vivamus massa orci eu. Id in eu, in sit velit. Risus pellentesque augue in augue faucibus purus sed. Rhoncus, ac sagittis, orci et eu. Sollicitudin integer aliquam commodo justo, lacinia suspendisse sit sed auctor.
					</Text>
					<Button
						width="260px"
						height="45px"
						border-radius="8px"
						background="#000000"
						color="#ffffff"
						font="normal 700 14px/1.5 --fontFamily-googleMontserrat"
						text-transform="uppercase"
					>
						Button
					</Button>
				</Box>
				<Box
					quarkly-title="contentSection"
					width="100%"
					height="max-content"
					max-width="600px"
					display="flex"
					flex-wrap="wrap"
					justify-content="flex-end"
					flex-direction="column"
				>
					<Box
						width="100%"
						max-width="600px"
						background="#ffffff"
						border-width="2px"
						border-style="solid"
						border-color="#000000"
						border-radius="8px"
						padding="40px 40px 40px 40px"
						quarkly-title=" companyabout"
						min-height="383px"
						sm-margin="0 10px 0 10px"
						sm-padding="24px 24px 24px 24px"
						sm-display="none"
					>
						<Text margin="0px 0px 0px 0px" font="400 16px --fontFamily-googleMontserrat" color="rgba(0, 0, 0, 0.8)" quarkly-title="aboutText">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies amet scelerisque eu pellentesque. Est placerat donec magna consequat pretium venenatis amet dolor. Enim praesent molestie quam vivamus massa orci eu. Id in eu, in sit velit. Risus pellentesque augue in augue faucibus purus sed. Rhoncus, ac sagittis, orci et eu. Sollicitudin integer aliquam commodo justo, lacinia suspendisse sit sed auctor.
						</Text>
					</Box>
					<Box
						width="600px"
						max-width="600px"
						quarkly-title="costylyok"
						display="flex"
						flex-direction="column"
						justify-content="center"
						align-items="center"
						padding="0px 0 0px 0"
						height="0px"
					/>
					<Box
						width="100%"
						max-width="600px"
						background="#ffffff"
						border-width="2px"
						border-style="solid"
						border-color="#000000"
						border-radius="8px"
						quarkly-title="jobs"
						display="flex"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						padding="40px 40px 40px 40px"
						min-height="383px"
						sm-padding="24px 24px 24px 24px"
						sm-margin="0px 8px 0px 8px"
					>
						<Text
							margin="0px 0px 45px 0px"
							text-align="center"
							font="700 20px/28.8px --fontFamily-googleMontserrat"
							quarkly-title="H2"
							text-transform="uppercase"
						>
							вакансии
						</Text>
						<Box
							min-width="100px"
							min-height="0px"
							display="flex"
							width="100%"
							justify-content="space-between"
							padding="16px 0 16px 0"
							border-width="0 0 1px 0"
							border-style="solid"
							border-color="#EEEEEE"
							height="max-content"
							quarkly-title="jobId"
						>
							<Text
								margin="0px 0px 0px 0px"
								text-align="center"
								font="400 20px/28.8px --fontFamily-googleMontserrat"
								quarkly-title="text"
								width="max-content"
								height="max-content"
							>
								Big brand name
							</Text>
							<Image
								src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/Vector%2083.png?v=2022-01-19T10:36:05.196Z"
								display="block"
								object-fit="cover"
								width="38px"
								height="20px"
								margin="0 0 0px 0"
							/>
						</Box>
					</Box>
					<Box
						width="100%"
						max-width="600px"
						background="#ffffff"
						border-width="2px"
						border-style="solid"
						border-color="#000000"
						border-radius="8px"
						quarkly-title="contacts"
						display="flex"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						padding="40px 40px 40px 40px"
						min-height="383px"
						sm-padding="24px 24px 24px 24px"
						sm-margin="0px 8px 0px 8px"
					>
						<Text
							margin="0px 0px 45px 0px"
							text-align="center"
							font="700 20px/28.8px --fontFamily-googleMontserrat"
							quarkly-title="H2"
							text-transform="uppercase"
						>
							контакты
						</Text>
						<Box
							min-width="100px"
							min-height="0px"
							display="flex"
							width="100%"
							justify-content="space-between"
							padding="16px 0 16px 0"
							border-width="0 0 1px 0"
							border-style="solid"
							border-color="#EEEEEE"
							height="max-content"
							flex-direction="row"
						>
							<Image
								src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/instagram.png?v=2022-01-19T10:54:25.500Z"
								display="block"
								object-fit="cover"
								width="24px"
								height="24px"
								margin="0 19px 0px 0"
							/>
							<Box
								min-width="100px"
								min-height="0px"
								display="flex"
								width="100%"
								justify-content="space-between"
								padding="0px 0 0px 0"
								height="max-content"
								flex-direction="column"
							>
								<Text
									margin="0px 0px 0px 0px"
									text-align="center"
									font="400 20px/28.8px --fontFamily-googleMontserrat"
									quarkly-title="text"
									width="max-content"
									height="max-content"
								>
									Instagram
								</Text>
								<Text
									margin="0px 0px 0px 0px"
									text-align="center"
									font="550 20px/28.8px --fontFamily-googleMontserrat"
									quarkly-title="textHight"
									width="max-content"
									height="max-content"
									color="#8E7AE0"
								>
									@brand
								</Text>
							</Box>
						</Box>
						<Box
							min-width="100px"
							min-height="0px"
							display="flex"
							width="100%"
							justify-content="space-between"
							padding="16px 0 16px 0"
							border-width="0 0 1px 0"
							border-style="solid"
							border-color="#EEEEEE"
							height="max-content"
							flex-direction="row"
						>
							<Image
								src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/instagram.png?v=2022-01-19T10:54:25.500Z"
								display="block"
								object-fit="cover"
								width="24px"
								height="24px"
								margin="0 19px 0px 0"
							/>
							<Box
								min-width="100px"
								min-height="0px"
								display="flex"
								width="100%"
								justify-content="space-between"
								padding="0px 0 0px 0"
								height="max-content"
								flex-direction="column"
							>
								<Text
									margin="0px 0px 0px 0px"
									text-align="center"
									font="400 20px/28.8px --fontFamily-googleMontserrat"
									quarkly-title="text"
									width="max-content"
									height="max-content"
								>
									Instagram
								</Text>
								<Text
									margin="0px 0px 0px 0px"
									text-align="center"
									font="550 20px/28.8px --fontFamily-googleMontserrat"
									quarkly-title="textHight"
									width="max-content"
									height="max-content"
									color="#8E7AE0"
								>
									@brand
								</Text>
							</Box>
						</Box>
						<Box
							min-width="100px"
							min-height="0px"
							display="flex"
							width="100%"
							justify-content="space-between"
							padding="16px 0 16px 0"
							border-width="0 0 1px 0"
							border-style="solid"
							border-color="#EEEEEE"
							height="max-content"
							flex-direction="row"
						>
							<Image
								src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/instagram.png?v=2022-01-19T10:54:25.500Z"
								display="block"
								object-fit="cover"
								width="24px"
								height="24px"
								margin="0 19px 0px 0"
							/>
							<Box
								min-width="100px"
								min-height="0px"
								display="flex"
								width="100%"
								justify-content="space-between"
								padding="0px 0 0px 0"
								height="max-content"
								flex-direction="column"
							>
								<Text
									margin="0px 0px 0px 0px"
									text-align="center"
									font="400 20px/28.8px --fontFamily-googleMontserrat"
									quarkly-title="text"
									width="max-content"
									height="max-content"
								>
									Instagram
								</Text>
								<Text
									margin="0px 0px 0px 0px"
									text-align="center"
									font="550 20px/28.8px --fontFamily-googleMontserrat"
									quarkly-title="textHight"
									width="max-content"
									height="max-content"
									color="#8E7AE0"
								>
									@brand
								</Text>
							</Box>
						</Box>
					</Box>
					<Box
						quarkly-title="content"
						width="100%"
						height="max-content"
						max-width="600px"
						display="flex"
						flex-wrap="wrap"
						justify-content="flex-end"
					/>
				</Box>
			</Box>
			<Box
				quarkly-title="contentMain"
				width="100%"
				height="28px"
				max-width="1200px"
				flex-wrap="wrap"
				display="flex"
				padding="0 0 0 0"
				margin="-28px 0 0 0"
				sm-margin="60px 0 0 0"
				sm-align-items="center"
				sm-justify-content="center"
			>
				<Image src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/logo.png?v=2022-01-19T11:13:01.852Z" display="block" />
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60feb3ae63f5070017af70c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"619b78f310dffeaeba606725"} />
			<style place={"endOfBody"} rawKey={"619b7917f5e4ef1492682e06"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"620d0b13860a3cd523791a26"}>
				{"<!-- Yandex.Metrika counter -->\n<script type=\"text/javascript\" >\n   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(87531028, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true,\n        webvisor:true\n   });\n</script>\n<noscript><div><img src=\"https://mc.yandex.ru/watch/87531028\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div></noscript>\n<!-- /Yandex.Metrika counter -->"}
			</script>
		</RawHtml>
	</Theme>;
});